import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    strings: any;
}

const PersonalInformation = (props: Props) => {
    const { className, strings } = props;

    return (
        <div className={className}>
            Personal Information
        </div>
    );
};

export default styled(PersonalInformation)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #cccccc;
    background-color: white;
`;
