import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, IconButton, MenuItem, Menu, Tooltip } from '@mui/material';
import { language } from '../Language';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RowingIcon from '@mui/icons-material/Rowing';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SchoolIcon from '@mui/icons-material/School';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

interface Props {
  className?: string;
  toggleSideMenu: () => void;
  isLoggedIn: boolean;
  isSmallScreen: boolean;
}

interface MenuItemInterface {
  name: string;
  link: string;
  icon: any;
}

const strings = language().menu.top;

const middleMenu: MenuItemInterface[] = [
  {
    name: 'dashboard',
    link: '/',
    icon: <DashboardIcon />,
  },
  {
    name: 'planner',
    link: '/planner',
    icon: <EditCalendarIcon />,
  },
  {
    name: 'extracurriculars',
    link: '/extra-curriculars',
    icon: <RowingIcon />,
  },
  {
    name: 'essays',
    link: '/essays',
    icon: <ModeEditOutlineIcon />,
  },
  {
    name: 'colleges',
    link: '/colleges',
    icon: <SchoolIcon />,
  },
];

const TopMenu = (props: Props) => {
  const { className, toggleSideMenu, isLoggedIn, isSmallScreen } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();
  const location = useLocation();

  const rightSide = (
    <Tooltip title={strings.gear}>
      <IconButton
        onClick={toggleSideMenu}
        sx={{
          borderRadius: '50%',
        }}
      >
        <SettingsIcon
          sx={{
            animation: 'spin 7s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
            color: '#666666',
          }}
        />
      </IconButton>
    </Tooltip>
  );

  return (
    <Grid className={className} container spacing={2}>
      <Grid item id='left' xs={6} md={3}>
        {isSmallScreen && isLoggedIn && (
          <IconButton onClick={handleClick}>
            <MenuIcon />
          </IconButton>
        )}
        <div id='logo' onClick={() => navigate('/')}>
          <img src='/favicon.ico' alt='Favicon' />
        </div>
      </Grid>
      {isLoggedIn && (
        <>
          {!isSmallScreen && (
            <Grid item md={6} id='middle'>
              {middleMenu.map(({ link, icon, name }) => (
                <div key={name} onClick={() => navigate(link)} id={location.pathname === link ? 'selected' : 'not-selected'}>
                  {icon}
                  {(strings as any)[name]}
                </div>
              ))}
            </Grid>
          )}
          <Grid item xs={6} md={3} id='right'>
            {rightSide}
          </Grid>
        </>
      )}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {middleMenu.map(({ link, icon, name }) => (
          <MenuItem
            key={name}
            onClick={() => {
              navigate(link);
              handleClose();
            }}
          >
            {icon}
            {(strings as any)[name]}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default styled(TopMenu)`
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
  }

  #left {
    #logo:hover {
      cursor: pointer;
    }

    #logo {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 30px;
    }
  }

  #middle {
    display: flex;
    justify-content: center;
  
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;

      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary.dark};
      }
    }

    #selected {
      border-bottom: ${({ theme }) => `2px solid ${theme.colors.primary.dark}`};
    }
  }

  #right {
    display: flex;
    justify-content: flex-end;
  }
`;
