import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Greetings from './Greetings';
import ProfileProgress from './ProfileProgress';
import Deadlines from './Deadlines';
import Activities from './Activities';
import EssayEditor from './EssayEditor';
import Planner from './Planner';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.dashboard;

const Dashboard = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <Grid container className={className}>
            {initialLoading ? <CircularProgress /> : (
                <>
                    <Grid item className='dashboard-component' xs={12} sm={4}>
                        <Greetings strings={strings.greetings} />
                    </Grid>
                    <Grid item className='dashboard-component' xs={12} sm={8}>
                        <ProfileProgress strings={strings.profileProgress} />
                    </Grid>
                    <Grid item className='dashboard-component' xs={12} sm={8}>
                        <Deadlines strings={strings.deadlines} />
                    </Grid>
                    <Grid item className='dashboard-component' xs={12} sm={4}>
                        <Activities strings={strings.activities} />
                    </Grid>
                    <Grid item className='dashboard-component' xs={12} sm={6}>
                        <EssayEditor strings={strings.essayEditor} />
                    </Grid>
                    <Grid item className='dashboard-component' xs={12} sm={6}>
                        <Planner strings={strings.planner} />
                    </Grid>
                </>

            )}
        </Grid>
    );
};

export default styled(Dashboard)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dashboard-component {
        &:hover:not(:first-of-type) {
            transform: scale(1.01, 1.1);
            transition: transform 0.2s;
            cursor: pointer;
        }

        div {
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            border: 1px solid #cccccc;
            background-color: white;
            display: flex;
        }
    }
`;
