import { Avatar, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { storeContext } from '../../store';

interface Props {
    className?: string;
    strings: any;
}

const Status = (props: Props) => {
    const { className, strings } = props;
    const { account: { firstName, lastName }, localLogOut } = useContext(storeContext);

    return (
        <div className={className}>
            <div>
                <Avatar
                    sx={{ bgcolor: 'primary.main' }}
                    sizes='large'
                    variant='rounded'
                >
                    {`${firstName.charAt(0)}${lastName.charAt(0)}`}
                </Avatar>
            </div>
            <div>
                <Typography variant='h4'>{`${firstName} ${lastName}`}</Typography>
            </div>
            <div>
                <Button variant='outlined' size='small' onClick={localLogOut}>{strings.logOut}</Button>
            </div>
        </div>
    );
};

export default styled(Status)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #cccccc;
    background-color: white;
    
    div {
        padding: 5px;
    }
`;
