import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const Essays = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <Grid container className={className}>
            {initialLoading ? <CircularProgress /> : (
                <>
                    Essays
                </>
            )}
        </Grid>
    );
};

export default styled(Essays)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
