import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Status from './Status';
import PersonalInformation from './PersonalInformation';
import { language } from '../../Language';

interface Props {
    className?: string;
    initialLoading: boolean;
}

const strings = language().signedIn.profile;

const Profile = (props: Props) => {
    const { className, initialLoading } = props;

    return (
        <Grid container columnSpacing={3} className={className}>
            {initialLoading ? <CircularProgress /> : (
                <>
                    <Grid item xs={12} sm={3}>
                        <Status strings={strings.status} />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <PersonalInformation strings={strings.personalInformation} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default styled(Profile)`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
