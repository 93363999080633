import _axios, { AxiosRequestConfig } from 'axios';
import { WaitlistFormData } from '../Pages/Entry/Waitlist';
import { apiBaseUrl } from '../environment';
import { SignUpFormData } from '../Pages/Entry/SignUp/RightSide';
import { SignInFormData } from '../Pages/Entry/SignIn/RightSide';
import { FetchAccount, JoinWaitlistResponse, SignInResponse, SignUpResponse } from './response';

const POST = 'POST';
const GET = 'GET';

const axiosInstance = _axios.create({ baseURL: apiBaseUrl });
export const setAuthToken = (bearerToken = '') => axiosInstance.defaults.headers.common['Authorization'] = bearerToken ? `Bearer ${bearerToken}` : '';

const axios = async (axiosRequest: AxiosRequestConfig) => {
    try {
        return convertSnakeToCamel((await axiosInstance({ method: GET, ...axiosRequest })).data);
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export const joinWaitlist = (data: WaitlistFormData): Promise<JoinWaitlistResponse> => axios({ url: '/public/waitlist', method: POST, data });
export const signUp = (data: SignUpFormData): Promise<SignUpResponse> => axios({ url: '/public/sign-up', method: POST, data });
export const signIn = (data: SignInFormData): Promise<SignInResponse> => axios({ url: '/public/sign-in', method: POST, data });
export const fetchAccount = (): Promise<FetchAccount> => axios({ url: '/account' });

const convertSnakeToCamel = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(convertSnakeToCamel);
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = key.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
            return { ...acc, [camelKey]: convertSnakeToCamel(obj[key]) };
        }, {});
    } else {
        return obj;
    }
}
